import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPostPage = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <h1 className="title">{post.frontmatter.title}</h1>
      <h2 className="subtitle">
        {post.frontmatter.date} - {post.fields.readingTime.text}
      </h2>
      <hr />
      <article className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
      <hr />
      <div className="columns">
        {previous ? (
          <div className="column">
            <div className="card">
              <div className="card-content">
                <p className="subtitle">&larr; Previous post</p>
                <p className="title">
                  <Link to={`/blog/${previous.frontmatter.slug}/`}>{previous.frontmatter.title}</Link>
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {next ? (
          <div className="column">
            <div className="card">
              <div className="card-content">
                <p className="subtitle">Next post &rarr;</p>
                <p className="title">
                  <Link to={`/blog/${next.frontmatter.slug}/`}>{next.frontmatter.title}</Link>
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <hr />
    </Layout>
  );
};
export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
      }
      excerpt
      html
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;

export default BlogPostPage;
